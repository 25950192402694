import ContactForm from '@/components/Clients/ClientForm/Contacts/ContactForm/ContactForm.vue';
import UPDATE_CONTACT_MUTATION from '@/graphql/mutations/updateContact.gql';
import eventHub from '@/utils/eventHub';
export default {
  name: 'EditContact',
  components: { ContactForm },
  props: {
    client: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data: function() {
    return {
      UPDATE_CONTACT_MUTATION,
      contact: (this.data && {
        ...this.data,
        phones: this.data.phones.length > 0 ? this.data.phones : [{ type: null, phone: null }],
      }) || {
        name: null,
        jobTitle: null,
        phones: [{ type: null, phone: null }],
        email: null,
        location: null,
        countYearsTogether: null,
      },
    };
  },
  watch: {
    data(value) {
      this.contact = { ...value };
    },
  },
  methods: {
    updateContacts(store, { data: { updateContact } }) {
      if (updateContact) {
        const index = this.client.contacts.findIndex(c => c.id === updateContact.id);
        if (index !== -1) {
          this.client.contacts[index] = updateContact;
          this.client.contacts = [...this.client.contacts];
        }
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Contact ${updateContact.name} was updated successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
