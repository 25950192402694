import PlanForm from '@/components/Plans/PlanForm/PlanForm.vue';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import PERMISSIONS from '@/enums/permissions';
import PLAN_QUERY from '@/graphql/queries/plan.gql';
export default {
  name: 'EditPlan',
  components: { PlanForm, Breadcrumbs },
  props: { planId: { type: Number | String } },
  apollo: {
    plan: {
      query: PLAN_QUERY,
      variables() {
        return { id: this.planId };
      },
      error(e) {
        this.error = e;
      },
    },
  },
  data: () => ({
    plan: null,
    error: null,
    readonlyFields: ['typeOfPlan'],
  }),
  computed: {
    loading() {
      return this.$apollo.queries.plan.loading;
    },
    breadcrumbs() {
      return [
        {
          text: 'Plans',
          href: '/plans',
          permission: PERMISSIONS.VIEW_PLANS,
        },
        {
          text: (this.plan && this.plan.name) || '',
          href: '/plan-details/' + this.planId,
          permission: PERMISSIONS.VIEW_PLANS,
        },
        {
          text: 'Edit Plan',
          disabled: true,
        },
      ];
    },
  },
  methods: {
    onSubmit(plan) {
      this.plan = plan;
    },
  },
};
