import Vue from 'vue';
import { VDatePicker } from 'vuetify/lib';
import { formatDateUTC } from '@/utils/dateFormatter';
// Create Base Mixins and Define Custom Properties
const base = Vue.extend({ mixins: [VDatePicker] });
export default base.extend({
  name: 'DatePicker',
  components: {
    VDatePicker,
  },
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    label: {
      type: String,
    },
    closeOnContentClick: {
      default: true,
    },
    noTitle: {
      default: true,
    },
    minWidth: {
      default: '290',
    },
    nudgeBottom: {
      default: '40',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    formattedDate: {
      get() {
        return formatDateUTC(this.value);
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('change', value);
      },
    },
  },
  methods: {
    inputHandler(event) {
      this.$emit('input', event);
    },
    changeHandler(event) {
      this.$emit('change', event);
    },
  },
});
