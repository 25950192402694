import Btn from '@/components/Btn/Btn.vue';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import validateRules from '@/services/validateRules';
import PHONE_TYPES_QUERY from '@/graphql/queries/phoneTypes.gql';

export default {
  name: 'ContactForm',
  components: { Btn, NumberTextField },
  props: {
    btnLabel: { type: String, required: true },
    loading: { type: Boolean, default: false },
    contact: { type: Object },
  },
  apollo: {
    phoneTypes: {
      query: PHONE_TYPES_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load phone type list.' }
          : error;
      },
    },
  },
  data: () => ({
    nameRules: [v => !!v || 'Name is Required'],
    jobTitleRules: [v => !!v || 'Job Title is Required'],
    emailRules: [validateRules.email],
    phoneTypes: [],
    error: null,
  }),
  methods: {
    phoneTypeRules(item) {
      return [
        v => (v === null && (item.phone === null || item.phone === '')) || v !== null || 'Phone Type is Required',
      ];
    },
    phoneRules(item) {
      return [
        v =>
          ((v === null || v === '') && (item.type === null || item.type === '')) ||
          (v !== null && v !== '') ||
          'Phone is Required',
      ];
    },
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit() {
      if (this.validate()) {
        this.$emit('submit', this.contact);
      }
    },
    onPhoneClick(index) {
      if (index === 0) {
        this.contact.phones.push({ type: null, phone: null });
      } else {
        this.contact.phones.splice(index, 1);
      }
    },
  },
};
