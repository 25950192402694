import Btn from '@/components/Btn/Btn.vue';
import ClientInfo from '@/components/ClientInfo/ClientInfo.vue';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';

export default {
  name: 'ClientSummary',
  components: { Btn, ClientInfo },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  props: {
    client: {
      type: Object,
      default: null,
    },
    loading: { type: Boolean },
  },
  data: () => ({
    currentUser: null,
  }),
  computed: {
    showCreatePlan() {
      return (
        accessByPermissions(PERMISSIONS.CREATE_PLAN, this.currentUser) &&
        this.client &&
        this.client.facilities &&
        this.client.facilities.length > 0
      );
    },
    redirectLink() {
      return accessByPermissions(PERMISSIONS.VIEW_CLIENTS, this.currentUser)
        ? { name: 'clients' }
        : { name: 'client-details', params: { clientId: this.client && this.client.id } };
    },
  },
  methods: {
    onBackClick() {
      this.$emit('back');
    },
  },
};
