var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({staticClass:"add-component-form",attrs:{"mutation":_vm.ADD_COMPONENT_OF_NEED_MUTATION,"variables":{
    input: {
      facilityId: (_vm.component.facility && _vm.component.facility.id) || null,
      systemId: (_vm.component.system && _vm.component.system.id) || null,
      componentId: (_vm.component.component && _vm.component.component.id) || null,
      status: _vm.component.status,
      yearCompleted: _vm.component.yearCompleted,
      actualCost: _vm.component.actualCost,
      description: _vm.component.description,
      componentFile: _vm.component.componentFile,
      yearForImprovement: (_vm.component.yearForImprovement && _vm.component.yearForImprovement.toString()) || null,
      quantityOfComponents: _vm.component.quantityOfComponents,
      costPerUnit: _vm.component.costPerUnit,
      unitId: (_vm.component.unit && _vm.component.unit.id) || null,
      conditionAssessmentId: (_vm.component.conditionAssessment && _vm.component.conditionAssessment.id) || null,
      locationOfComponent: _vm.component.locationOfComponent,
      fundingSourceId: (_vm.component.fundingSource && _vm.component.fundingSource.id) || null,
      budgetaryNotes: _vm.component.budgetaryNotes,
      internalNotes: _vm.component.internalNotes,
      componentNotes: _vm.component.componentNotes,
      softCost: _vm.component.softCost,
      flag: _vm.component.flag,
      planId: (_vm.plan && _vm.plan.id) || null,
    },
  },"update":_vm.updateComponents},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var mutate = ref.mutate;
  var loading = ref.loading;
  var error = ref.error;
return [(!loading)?_c('alert',{attrs:{"align":"center","error":error}}):_vm._e(),_c('component-form',_vm._b({on:{"submit":function($event){return mutate()}}},'component-form',{
        btnLabel: 'Add',
        loading: loading,
        component: _vm.component,
        plan: _vm.plan,
        client: (_vm.plan && _vm.plan.client) || null,
      },false))]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }