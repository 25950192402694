import Btn from '@/components/Btn/Btn.vue';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import validateRules from '@/services/validateRules';
import UNITS_QUERY from '@/graphql/queries/units.gql';
import SYSTEMS_QUERY from '@/graphql/queries/systems.gql';
import CONDITION_QUERY from '@/graphql/queries/conditionAssessment.gql';
import FUNDING_SOURCE_QUERY from '@/graphql/queries/fundingSource.gql';
import ADD_FUNDING_SOURCE_MUTATION from '@/graphql/mutations/addFundingSource.gql';
import Upload from '@/components/Upload/Upload.vue';
import { getBaseAmount, getBudgetAmount, getComponentSubtotal } from '@/services/componentBudget';
import { generateRangeArray } from '@/utils/generator';
import { COMPONENT_STATUS, COMPONENT_STATUS_LABELS } from '@/enums/componentStatus';
import { sortByName } from '@/utils/sortByName';
import Modal from '@/components/Modal/Modal.vue';
import eventHub from '@/utils/eventHub';
import { errorFilter } from '@/filters/errorMsg';

export default {
  name: 'ComponentForm',
  components: { Btn, NumberTextField, Upload, Modal },
  props: {
    btnLabel: { type: String, required: true },
    loading: { type: Boolean, default: false },
    component: { type: Object },
    readonlyFields: { type: Array },
    plan: { type: Object },
    client: { type: Object },
    isEdit: { type: Boolean, default: false },
  },
  apollo: {
    units: {
      query: UNITS_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load unit list.' }
          : error;
      },
    },
    systems: {
      query: SYSTEMS_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load system list.' }
          : error;
      },
    },
    conditionAssessment: {
      query: CONDITION_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load condition assessment list.' }
          : error;
      },
    },
    fundingSource: {
      query: FUNDING_SOURCE_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load funding source list.' }
          : error;
      },
    },
  },
  data: vm => {
    const startYear = vm.plan && vm.plan.financialInfo && parseInt(vm.plan.financialInfo.planStartYear);
    const years = startYear ? generateRangeArray(startYear, startYear + 19) : [];
    const upload =
      vm.component && vm.component.componentUrl
        ? new File([''], vm.component.componentUrl, { type: 'image/plain' })
        : null;
    return {
      ...validateRules,
      systems: [],
      statuses: [
        { name: COMPONENT_STATUS_LABELS[COMPONENT_STATUS.OPEN], value: COMPONENT_STATUS.OPEN },
        { name: COMPONENT_STATUS_LABELS[COMPONENT_STATUS.COMPLETED], value: COMPONENT_STATUS.COMPLETED },
      ],
      units: [],
      conditionAssessment: [],
      yearCompletedRules: [v => (v !== null && v !== '') || 'Year completed is Required'],
      years: years,
      upload: upload,
      status: vm.component.status.autocapitalize,
      quantityOfComponentsRules: [v => (v !== null && v !== '') || 'Quantity of Components is Required'],
      unitRules: [v => !!v || 'Unit is Required'],
      actualCostRules: [validateRules.positiveNumber, validateRules.floatNumber(2)],
      costRules: [
        v => (v !== null && v !== '') || 'Cost is Required',
        validateRules.positiveNumber,
        validateRules.floatNumber(2),
      ],
      fundingSource: [],
      facilityRules: [v => !!v || 'Facility is Required'],
      systemRules: [v => !!v || 'System is Required'],
      componentRules: [v => !!v || 'Component is Required'],
      statusRules: [v => !!v || 'Status is Required'],
      yearForImprovementRules: [v => !!v || 'Year Set for Improvement is Required'],
      conditionAssessmentRules: [v => !!v || 'Condition at Time of Assessment is Required'],
      fundingSourceRules: [v => !!v || 'Funding Source is Required'],
      fundingSourceNameRules: [v => !!v || 'Name is Required'],
      newSourceName: '',
      descriptionRules: [v => !v || (v && v.length <= 500) || 'Max 500 characters'],
    };
  },
  computed: {
    displayedSystems() {
      return Array.isArray(this.systems) ? this.systems.sort(sortByName) : [];
    },
    components() {
      const selected = this.component.system && this.displayedSystems.find(s => s.id === this.component.system.id);
      const result = selected ? selected.components || [] : [];
      return result.sort(sortByName);
    },
    isCompleted() {
      return this.component.status === COMPONENT_STATUS.COMPLETED;
    },
    baseAmount() {
      const { quantityOfComponents, costPerUnit } = this.component;
      return getBaseAmount(quantityOfComponents, costPerUnit, this.plan.financialInfo.regionalCost);
    },
    componentSubtotal() {
      const { planStartYear, inflationCostEscalation } = this.plan.financialInfo;
      return getComponentSubtotal(
        this.baseAmount,
        planStartYear,
        this.component.yearForImprovement,
        inflationCostEscalation
      );
    },
    budgetAmount() {
      return getBudgetAmount(
        this.componentSubtotal,
        this.plan.financialInfo.softCostPercentage,
        this.component.softCost
      );
    },
    facilities() {
      return this.client && Array.isArray(this.client.facilities) && this.client.facilities.sort(sortByName);
    },
    conditionByLabel() {
      return this.conditionAssessment.reduce((accum, current) => {
        accum[current.name] = current;
        return accum;
      }, {});
    },
  },
  watch: {
    'component.system'() {
      this.component.component = null;
      this.component.costPerUnit = null;
      this.component.unit = null;
    },
    'component.component'(value) {
      if (value !== null) {
        this.component.costPerUnit = value.costPerUnit;
        this.component.unit = value.unit;
      }
    },
    isCompleted(value) {
      if (!value) {
        this.component.yearCompleted = null;
        this.component.actualCost = null;
        this.component.componentNotes = null;
      }
    },
    fundingSource(sources) {
      if (sources && this.plan.financialInfo.typeOfPlan && this.component.fundingSource === null) {
        this.component.fundingSource = sources.find(s => this.plan.financialInfo.typeOfPlan.id === s.id) || null;
      }
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit() {
      if (this.validate()) {
        this.component.componentFile =
          this.upload && this.upload.name === this.component.componentUrl ? null : this.upload;
        this.component.componentUrl = this.component.componentUrl && !this.upload ? null : this.component.componentUrl;
        this.$nextTick(() => {
          this.$emit('submit', this.component);
        });
      }
    },
    openSourceModal() {
      if (this.$refs.source_modal) {
        this.$refs.source_modal.modalOpen = true;
      }
    },
    addSource(close) {
      this.validate();
      if (this.newSourceName) {
        let resulutSource;
        this.$apollo
          .mutate({
            mutation: ADD_FUNDING_SOURCE_MUTATION,
            variables: {
              input: {
                name: this.newSourceName,
              },
            },
            update: (store, { data: { addFundingSource } }) => {
              if (addFundingSource) {
                resulutSource = addFundingSource;
                const data = store.readQuery({
                  query: FUNDING_SOURCE_QUERY,
                });
                if (Array.isArray(data['fundingSource'])) {
                  data['fundingSource'].push(addFundingSource);
                  data['fundingSource'].sort(sortByName);
                  store.writeQuery({
                    query: FUNDING_SOURCE_QUERY,
                    data,
                  });
                }
              }
            },
          })
          .then(() => {
            eventHub.$emit('show-snackbar', {
              color: 'success',
              text: `Funding Source "${this.newSourceName}" is created!`,
            });
            this.component.fundingSource = resulutSource;
            this.newSourceName = '';
            typeof close === 'function' && close();
          })
          .catch(e => {
            eventHub.$emit('show-snackbar', { color: 'error', text: errorFilter(e.message) });
            console.warn('Add Funding Source error', e);
          });
      }
    },
    onYearForImprovementChange(value) {
      const year = Number(value);
      if (year) {
        const diff = year - new Date().getFullYear();
        switch (true) {
          case diff < 3:
            this.conditionByLabel['Poor'] && (this.component.conditionAssessment = this.conditionByLabel['Poor']);
            break;
          case diff < 7:
            this.conditionByLabel['Fair'] && (this.component.conditionAssessment = this.conditionByLabel['Fair']);
            break;
          case diff < 20:
            this.conditionByLabel['Good'] && (this.component.conditionAssessment = this.conditionByLabel['Good']);
            break;
        }
      }
    },
  },
};
