const validateRules = {
  positiveNumber: v => v === null || v === '' || (v >= 0 && v <= 99999999999) || 'Please enter a positive number',
  integerNumber: v => v === null || v === '' || /^\d+$/.test(v) || 'Please enter a whole number',
  floatNumber: toFixed => v => {
    switch (toFixed) {
      case 1:
        return v === null || v === '' || /^\d+(\.\d{1})?$/.test(v) || 'Please enter value in the correct format: XX.X';
      case 2:
        return (
          v === null || v === '' || /^\d+(\.\d{1,2})?$/.test(v) || 'Please enter value in the correct format: XX.XX'
        );
      default:
        return v === null || v === '' || /^\d+(\.\d)?$/.test(v) || 'Please enter value in the correct format: XX.X';
    }
  },
  numberInRange: (min, max) => v =>
    v === null || v === '' || (v >= min && v <= max) || `Please enter value between ${min} and ${max}`,
  email: v => !v || /.+@.+\..+/.test(v) || 'Email must be valid',
};

export default validateRules;
