import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import AddContact from './AddContact/AddContact.vue';
import ContactList from './ContactList/ContactList.vue';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';

export default {
  name: 'Contacts',
  components: { Btn, Modal, AddContact, ContactList },
  props: {
    client: {
      type: Object,
      default: null,
    },
    loading: { type: Boolean },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    currentUser: null,
  }),
  computed: {
    showAddContactBtn() {
      return accessByPermissions(PERMISSIONS.ADD_CONTACT, this.currentUser);
    },
  },
  methods: {
    onNextClick() {
      this.$emit('done');
    },
    onBackClick() {
      this.$emit('back');
    },
  },
};
