import ComponentForm from '@/components/Plans/PlanForm/Components/ActiveComponents/ComponentForm/ComponentForm.vue';
import UPDATE_COMPONENT_OF_NEED_MUTATION from '@/graphql/mutations/updateComponentOfNeed.gql';
import eventHub from '@/utils/eventHub';
import { COMPONENT_STATUS } from '@/enums/componentStatus';
import COMPONENTS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';

export default {
  name: 'EditComponent',
  components: { ComponentForm },
  props: {
    plan: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data: function() {
    return {
      UPDATE_COMPONENT_OF_NEED_MUTATION,
      component: (this.data && { ...this.data, componentFile: null }) || {
        system: null,
        facility: null,
        component: null,
        status: COMPONENT_STATUS.OPEN,
        yearCompleted: null,
        actualCost: null,
        description: null,
        componentUrl: null,
        componentFile: null,
        yearForImprovement: null,
        quantityOfComponents: null,
        unit: null,
        costPerUnit: null,
        baseAmount: null,
        componentSubtotal: null,
        budgetAmount: null,
        conditionAssessment: null,
        locationOfComponent: null,
        fundingSource: null,
        budgetaryNotes: null,
        internalNotes: null,
        componentNotes: null,
        softCost: false,
        flag: false,
      },
    };
  },
  watch: {
    data(value) {
      this.component = { ...value };
    },
  },
  methods: {
    updateComponents(store, { data: { updateComponentOfNeed } }) {
      if (updateComponentOfNeed) {
        if (updateComponentOfNeed.status === COMPONENT_STATUS.COMPLETED) {
          const data = store.readQuery({
            query: COMPONENTS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: COMPONENT_STATUS.OPEN,
            },
          });
          if (data) {
            data.componentsOfNeed = data.componentsOfNeed.filter(c => c.id !== updateComponentOfNeed.id);
            store.writeQuery({
              query: COMPONENTS_OF_NEEDS,
              variables: {
                planId: this.plan.id,
                status: COMPONENT_STATUS.OPEN,
              },
              data,
            });
          }
        }
        try {
          const data = store.readQuery({
            query: COMPONENTS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: updateComponentOfNeed.status,
            },
          });
          if (data) {
            const index = data.componentsOfNeed.findIndex(c => c.id === updateComponentOfNeed.id);
            if (index !== -1) {
              data.componentsOfNeed[index] = updateComponentOfNeed;
              data.componentsOfNeed = [...data.componentsOfNeed];
            } else {
              data.componentsOfNeed.unshift(updateComponentOfNeed);
            }
            store.writeQuery({
              query: COMPONENTS_OF_NEEDS,
              variables: {
                planId: this.plan.id,
                status: updateComponentOfNeed.status,
              },
              data,
            });
          }
        } catch (e) {
          //
        }
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Component was updated successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
