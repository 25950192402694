<template>
  <div class="add-plan">
    <plan :clientId="$route.params.clientId"></plan>
  </div>
</template>

<script>
import Plan from '@/components/Plans/AddPlan/AddPlan.vue';
export default {
  name: 'AddPlan',
  components: { Plan },
};
</script>
