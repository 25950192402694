var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{attrs:{"items":_vm.displayedItems,"headers":_vm.displayedHeaders,"height":"611px","item-key":"id","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.canViewDetails)?_c('router-link',{attrs:{"to":{ name: 'client-details', params: { clientId: item.id } }}},[_vm._v(_vm._s(item.name))]):[_vm._v(_vm._s(item.name))]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-actions-bar"},[(_vm.showEditBtn)?_c('btn',{staticClass:"action edit mr-2",attrs:{"icon":"","link":"","to":{ name: 'client', params: { clientId: item.id } },"color-type":null}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" far fa-edit ")])],1):_vm._e(),(_vm.showDeleteBtn)?_c('confirmation-modal',{attrs:{"mutation":_vm.DELETE_CLIENT_MUTATION,"variables":{ id: item.id },"title":"Delete Client","message":"Are you sure you want to delete client?","ok-btn":{ label: 'Delete' },"update":_vm.onDelete(item)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var modalOn = ref.on;
var attrsModal = ref.attrs;
return [_c('v-tooltip',{attrs:{"max-width":"300","top":"","nudge-top":"-10","disabled":item.canDelete,"open-delay":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({staticClass:"action",attrs:{"small":"","disabled":!item.canDelete}},'v-icon',attrsModal,false),modalOn),[_vm._v(" far fa-trash-alt ")])],1)]}}],null,true)},[_vm._v(" The client has a plan(s). First, you need to delete plan(s) ")])]}}],null,true)}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }