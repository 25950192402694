import Btn from '@/components/Btn/Btn.vue';
import CLIENT_TYPES_QUERY from '@/graphql/queries/clientTypes.gql';
import ICS_OFFICES_QUERY from '@/graphql/queries/icsOffices.gql';
import ADD_CLIENT_MUTATION from '@/graphql/mutations/addClient.gql';
import UPDATE_CLIENT_MUTATION from '@/graphql/mutations/updateClient.gql';
import { isEqual } from 'lodash';
import eventHub from '@/utils/eventHub';
import Upload from '@/components/Upload/Upload.vue';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';

const copy = (obj1, obj2) =>
  Object.keys(obj1).reduce((a, key) => ({ ...a, [key]: obj2[key] !== undefined ? obj2[key] : null }), {});

export default {
  name: 'ClientGeneralInfoForm',
  components: { Btn, Upload, NumberTextField },
  props: {
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    client: { type: Object },
  },
  apollo: {
    clientTypes: {
      query: CLIENT_TYPES_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load client type list.' }
          : error;
      },
    },
    icsOffices: {
      query: ICS_OFFICES_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load ics office list.' }
          : error;
      },
    },
  },
  data: () => ({
    form: {
      name: null,
      district: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      county: null,
      description: null,
      icsOffice: null,
      clientType: null,
      logoUrl: null,
      upload: null,
    },
    nameRules: [v => !!v || 'Name is Required'],
    addressRules: [v => !!v || 'Address is Required'],
    cityRules: [v => !!v || 'City is Required'],
    stateRules: [v => !!v || 'State is Required'],
    zipRules: [
      v => !!v || 'Zip Code is Required',
      v => (v && v.toString().length === 5) || 'Zip Code Must Contain 5 Characters',
    ],
    icsOfficeRules: [v => !!v || 'ICS Office is Required'],
    icsOffices: [],
    clientTypeRules: [v => !!v || 'Client Type is Required'],
    clientTypes: [],
    internalLoading: false,
    error: null,
    changes: new Set(),
  }),
  watch: {
    client: {
      immediate: true,
      handler(value) {
        if (value !== null) {
          this.error = null;
          this.form = {
            ...copy(this.form, value),
            ...(value.logoUrl ? { upload: new File([''], value.logoUrl, { type: 'image/plain' }) } : {}),
          };
          this.changes.clear();
        }
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onChange(key, value) {
      if (this.client) {
        if (!isEqual(this.client[key], value) || this.form.upload) {
          this.changes.add(key);
        } else if (this.changes.has(key)) {
          this.changes.delete(key);
        }
      } else {
        this.changes.add(key);
      }
      this.$emit('change');
    },
    onSubmit() {
      this.error = null;
      if (this.validate() && this.changes.size > 0) {
        const {
          name,
          district,
          address,
          city,
          state,
          zip,
          county,
          description,
          icsOffice,
          clientType,
          logoUrl,
          upload,
        } = this.form;
        const variables = {
          name,
          district,
          address,
          city,
          state,
          zip,
          county,
          description,
          logoFile: upload && upload.name === logoUrl ? null : upload,
          icsOfficeId: icsOffice.id,
          clientTypeId: clientType.id,
        };
        if (!this.client.id) {
          this.addClient(variables);
        } else {
          this.updateClient({ ...variables, id: this.client.id, logoUrl: logoUrl && !upload ? null : logoUrl });
        }
      } else if (this.validate() && this.changes.size === 0) {
        this.$emit('submit', this.client);
      }
    },
    onReset() {
      this.form = {
        ...copy(this.form, this.client),
        ...(this.client.logoUrl ? { upload: new File([''], this.client.logoUrl, { type: 'image/plain' }) } : {}),
      };
      this.error = null;
      this.$refs.form.resetValidation();
      this.changes.clear();
      this.$emit('reset');
    },
    addClient(variables) {
      this.internalLoading = true;
      this.$apollo
        .mutate({
          mutation: ADD_CLIENT_MUTATION,
          variables: {
            input: variables,
          },
        })
        .then(({ data: { addClient } }) => {
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Client ${addClient.name} was added successfully!`,
          });
          this.$emit('submit', addClient);
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
    updateClient(variables) {
      this.internalLoading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_CLIENT_MUTATION,
          variables: {
            input: variables,
          },
        })
        .then(({ data: { updateClient } }) => {
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Client was updated successfully!`,
          });
          this.$emit('submit', updateClient);
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
  },
};
