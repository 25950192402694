import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import AddComponent from './AddComponent/AddComponent.vue';
import ComponentList from './ComponentList/ComponentList.vue';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import COMPONETS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import { COMPONENT_STATUS } from '@/enums/componentStatus';
import DataTableColumns from '@/components/DataTableColumns/DataTableColumns.vue';
import BudgetCalculationInfo from '../BudgetCalculationInfo/BudgetCalculationInfo.vue';

const TABLE_UID = 'PLAN_ACTIVE_COMPONENTS';

export default {
  name: 'ActiveComponents',
  components: { Btn, Modal, AddComponent, ComponentList, DataTableColumns, BudgetCalculationInfo },
  props: {
    plan: {
      type: Object,
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    componentsOfNeed: {
      query: COMPONETS_OF_NEEDS,
      variables() {
        return {
          planId: (this.plan && this.plan.id) || null,
          status: COMPONENT_STATUS.OPEN,
        };
      },
      skip() {
        return this.plan === null || !this.plan.id;
      },
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load plan components.' }
          : error;
      },
    },
  },
  data: () => ({
    TABLE_UID,
    currentUser: null,
    componentsOfNeed: null,
    error: null,
    headers: [],
    selectedHeaders: null,
  }),
  computed: {
    loading() {
      return this.$apollo.queries.componentsOfNeed.loading;
    },
    showAddComponentBtn() {
      return accessByPermissions(PERMISSIONS.ADD_PLAN_COMPONENT, this.currentUser);
    },
    components() {
      return JSON.parse(JSON.stringify(this.componentsOfNeed)) || [];
    },
  },
  methods: {
    onInitHeaders(value) {
      this.headers = value;
    },
  },
};
