import Vue from 'vue';
function numberFilter(value) {
  if (value === null || value === undefined || value === '') return '';
  return value === '--'
    ? value
    : new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(Number(value)).toString();
}

Vue.filter('number', numberFilter);

export default numberFilter;
