import Modal from '@/components/Modal/Modal.vue';
import Btn from '@/components/Btn/Btn.vue';

export default {
  name: 'FileInputList',
  components: { Modal, Btn },
  props: {
    value: Array,
    max: Number,
    loading: Boolean,
    modal: Boolean,
  },
  data() {
    return { deleteIndex: -1 };
  },
  computed: {
    isMax() {
      return this.list.length + 1 > this.maxLength;
    },
    maxLength() {
      return this.max > 0 ? this.max : 10;
    },
    list() {
      const result = Array.isArray(this.value)
        ? this.value
            .map(item => {
              if (typeof item === 'string') {
                item = new File([''], item, { type: '' });
              }
              return item instanceof File ? item : null;
            })
            .slice(0, this.maxLength)
            .filter(item => item)
        : [];
      return result.length ? result : [new File([''], '', { type: '' })];
    },
  },
  methods: {
    del(index) {
      if (this.modal && this.list[index] && this.list[index].name) {
        this.deleteIndex = index;
        this.$refs.modal.modalOpen = true;
      } else {
        this.remove(index);
      }
    },
    approve() {
      this.deleteIndex > -1 && this.remove(this.deleteIndex);
      this.$refs.modal.modalOpen = false;
      this.deleteIndex = -1;
    },
    add() {
      this.list.push(new File([''], '', { type: '' }));
      this.input();
    },
    remove(index) {
      this.list.splice(index, 1);
      this.$emit('remove', index);
      this.input();
    },
    input() {
      this.$emit('input', this.list.slice());
    },
    change(index, file) {
      if (file instanceof File) {
        this.list[index] = file;
        this.input();
        this.$emit('change', {
          index,
          value: file,
        });
      }
    },
  },
};
