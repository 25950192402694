<template>
  <div class="edit-plan">
    <plan :plan-id="$route.params.planId"></plan>
  </div>
</template>

<script>
import Plan from '@/components/Plans/EditPlan/EditPlan.vue';
export default {
  name: 'EditPlan',
  components: { Plan },
};
</script>
