import COMPLETE_COMPONENT_OF_NEED_MUTATION from '@/graphql/mutations/completeComponentOfNeed.gql';
import eventHub from '@/utils/eventHub';
import CompleteComponentForm from '@/components/Plans/PlanForm/Components/ActiveComponents/CompleteComponentForm/CompleteComponentForm.vue';
import COMPONENTS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';
import { COMPONENT_STATUS } from '@/enums/componentStatus';

export default {
  name: 'CompleteComponent',
  components: { CompleteComponentForm },
  props: {
    plan: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data: vm => ({
    COMPLETE_COMPONENT_OF_NEED_MUTATION,
    component: (vm.data && { ...vm.data }) || {
      yearCompleted: null,
      actualCost: null,
      componentNotes: null,
    },
  }),
  watch: {
    data(value) {
      this.component = { ...value };
    },
  },
  methods: {
    updateComponents(store, { data: { completedComponentOfNeed } }) {
      if (completedComponentOfNeed) {
        let data = store.readQuery({
          query: COMPONENTS_OF_NEEDS,
          variables: {
            planId: this.plan.id,
            status: COMPONENT_STATUS.OPEN,
          },
        });
        if (data) {
          data.componentsOfNeed = data.componentsOfNeed.filter(c => c.id !== completedComponentOfNeed.id);
          store.writeQuery({
            query: COMPONENTS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: COMPONENT_STATUS.OPEN,
            },
            data,
          });
        }
        try {
          data = store.readQuery({
            query: COMPONENTS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: completedComponentOfNeed.status,
            },
          });
          if (data) {
            data.componentsOfNeed.unshift(completedComponentOfNeed);
            store.writeQuery({
              query: COMPONENTS_OF_NEEDS,
              variables: {
                planId: this.plan.id,
                status: completedComponentOfNeed.status,
              },
              data,
            });
          }
        } catch (e) {
          //
        }
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Status of the component was changes successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
