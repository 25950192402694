import Btn from '@/components/Btn/Btn.vue';
import CLIENTS_QUERY from '@/graphql/queries/clients.gql';
import ClientList from '@/components/Clients/ClientList/ClientList.vue';
export default {
  name: 'Clients',
  components: { Btn, ClientList },
  data: () => ({
    CLIENTS_QUERY,
  }),
};
