import Vue from 'vue';
import { VStepperStep } from 'vuetify/lib';
// Create Base Mixins and Define Custom Properties
const base = Vue.extend({ mixins: [VStepperStep] });
export default base.extend({
  name: 'StepperStep',
  components: {
    VStepperStep,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
  },
});
