import Stepper from '@/components/Stepper/Stepper.vue';
import StepperStep from '@/components/StepperStep/StepperStep.vue';
import ClientGeneralInfoForm from './ClientGeneralInfoForm/ClientGeneralInfoForm.vue';
import Contacts from './Contacts/Contacts.vue';
import Facilities from './Facilities/Facilities.vue';
import ClientSummary from './Summary/Summary.vue';
import Documents from './Documents/Documents.vue';

export default {
  name: 'ClientForm',
  components: { Stepper, StepperStep, ClientGeneralInfoForm, Contacts, Facilities, Documents, ClientSummary },
  props: {
    client: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    step: 1,
    lock: false,
    lastCompleted: 0,
    fileInputRules: [value => (value && value.length < 11) || 'You can choose 10 files maximum'],
  }),
  watch: {
    step(value) {
      if (value > this.lastCompleted) {
        this.lastCompleted = value - 1;
      }
    },
    client(value, old) {
      if (!old && value && value.id) {
        this.lastCompleted = 5;
      }
    },
  },
  methods: {
    onUpdateClientInfo(client) {
      this.step = 2;
      this.lock = false;
      this.$emit('submit', client);
    },
    onChangeClientInfo() {
      this.lock = true;
    },
    onResetChangesClientInfo() {
      this.lock = false;
    },
    onUpdateContacts() {
      this.step = 3;
    },
    onUpdateFacilities() {
      this.step = 4;
    },
    onUpdateDocuments() {
      this.step = 5;
    },
  },
};
