
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FinancialInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FinancialInfo"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"typeOfPlan"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"ltfmrFundingAmount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"capitalFundingAmount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"capitalInfusionAmount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"capitalInfusionYear"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"planStartYear"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"regionalCost"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fundingEscalation"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"inflationCostEscalation"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"softCostPercentage"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":285}};
    doc.loc.source = {"body":"fragment FinancialInfo on FinancialInfo {\n  id\n  typeOfPlan {\n    id\n    name\n  }\n  ltfmrFundingAmount\n  capitalFundingAmount\n  capitalInfusionAmount\n  capitalInfusionYear\n  planStartYear\n  regionalCost\n  fundingEscalation\n  inflationCostEscalation\n  softCostPercentage\n  __typename\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
