var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({staticClass:"components-report",attrs:{"mutation":_vm.CLONE_PLAN_MUTATION,"variables":{
    id: _vm.plan ? _vm.plan.id : null,
    name: _vm.name,
  },"update":_vm.onClonePlan},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var mutate = ref.mutate;
  var loading = ref.loading;
  var error = ref.error;
return [(!loading)?_c('alert',{attrs:{"align":"center","error":error}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Plan Name","rules":_vm.nameRules,"autofocus":"","autocomplete":"off","validate-on-blur":"","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('btn',{attrs:{"width":"200","disabled":_vm.isNameValid},on:{"click":function($event){return mutate()}}},[_vm._v("Clone")])]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }