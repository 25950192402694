import { getHeadersSelection, setHeadersSelection } from '@/services/userState';

export default {
  name: 'DataTableColumns',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    uId: {
      type: String,
    },
  },
  data: () => ({
    menuOpen: false,
    selected: [],
  }),
  watch: {
    headers: {
      immediate: true,
      handler(val) {
        if (this.value === null && val && val.length > 0) {
          //selected all by default
          let selected = val.map((_, index) => index);
          if (this.uId) {
            const headers = getHeadersSelection(this.uId);
            if (headers) {
              selected = [];
              const validHeaders = headers.every(h => {
                const index = val.findIndex(header => h.value === header.value);
                if (index > -1) {
                  selected.push(index);
                } else {
                  //stored headers is not up to date, need to clean up
                  setHeadersSelection(this.uId, undefined);
                  selected = [];
                }
                return index > -1;
              });
              if (!validHeaders) {
                //stored headers is not up to date, need to clean up
                setHeadersSelection(this.uId, undefined);
                selected = [];
              }
            }
          }
          this.selected = selected;
          this.$emit('change', selected);
        }
      },
    },
    value(val) {
      if (val !== null && JSON.stringify(this.selected) !== JSON.stringify(val)) {
        this.selected = val;
      }
    },
    menuOpen(value) {
      if (!value) {
        if (JSON.stringify(this.selected) !== JSON.stringify(this.value)) {
          if (this.uId) {
            const headers = this.selected.map(selectedIndex => this.headers[selectedIndex]);
            setHeadersSelection(this.uId, headers);
          }
          this.$emit('change', this.selected);
        }
      }
    },
  },
  methods: {
    onModalClose() {
      this.menuOpen = false;
    },
  },
};
