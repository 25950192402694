import ADD_COMPONENT_OF_NEED_MUTATION from '@/graphql/mutations/addComponentOfNeed.gql';
import eventHub from '@/utils/eventHub';
import ComponentForm from '@/components/Plans/PlanForm/Components/ActiveComponents/ComponentForm/ComponentForm.vue';
import { COMPONENT_STATUS } from '@/enums/componentStatus';
import COMPONETS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';

export default {
  name: 'AddComponent',
  components: { ComponentForm },
  props: {
    plan: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    ADD_COMPONENT_OF_NEED_MUTATION,
    component: {
      system: null,
      facility: null,
      component: null,
      status: COMPONENT_STATUS.OPEN,
      yearCompleted: null,
      actualCost: null,
      description: null,
      componentFile: null,
      yearForImprovement: null,
      quantityOfComponents: null,
      unit: null,
      costPerUnit: null,
      baseAmount: null,
      componentSubtotal: null,
      budgetAmount: null,
      conditionAssessment: null,
      locationOfComponent: null,
      fundingSource: null,
      budgetaryNotes: null,
      internalNotes: null,
      componentNotes: null,
      softCost: false,
      flag: false,
    },
  }),
  methods: {
    updateComponents(store, { data: { addComponentOfNeed } }) {
      if (addComponentOfNeed) {
        const data = store.readQuery({
          query: COMPONETS_OF_NEEDS,
          variables: {
            planId: this.plan.id,
            status: addComponentOfNeed.status,
          },
        });
        if (data) {
          data.componentsOfNeed.unshift(addComponentOfNeed);
          store.writeQuery({
            query: COMPONETS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: addComponentOfNeed.status,
            },
            data,
          });
        }

        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Component was added successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
