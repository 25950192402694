var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({staticClass:"complete-component",attrs:{"mutation":_vm.COMPLETE_COMPONENT_OF_NEED_MUTATION,"variables":{
    input: {
      id: _vm.component.id,
      yearCompleted: _vm.component.yearCompleted,
      actualCost: _vm.component.actualCost,
      componentNotes: _vm.component.componentNotes,
    },
  },"update":_vm.updateComponents},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var mutate = ref.mutate;
  var loading = ref.loading;
  var error = ref.error;
return [(!loading)?_c('alert',{attrs:{"align":"center","error":error}}):_vm._e(),_c('complete-component-form',_vm._b({on:{"submit":function($event){return mutate()}}},'complete-component-form',{
        loading: loading,
        component: _vm.component,
        plan: _vm.plan,
      },false))]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }