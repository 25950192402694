<template>
  <div class="edit-client">
    <client :client-id="$route.params.clientId"></client>
  </div>
</template>

<script>
import Client from '@/components/Clients/EditClient/EditClient.vue';
export default {
  name: 'EditClient',
  components: { Client },
};
</script>
