import Btn from '@/components/Btn/Btn.vue';
import PLAN_TYPES_QUERY from '@/graphql/queries/planTypes.gql';
import validateRules from '@/services/validateRules';
import ADD_FINANCIAL_INFO_MUTATION from '@/graphql/mutations/addFinancialInfo.gql';
import UPDATE_FINANCIAL_INFO_MUTATION from '@/graphql/mutations/updateFinancialInfo.gql';
import { isEqual } from 'lodash';
import eventHub from '@/utils/eventHub';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import { generateRangeArray } from '@/utils/generator';

const copy = (obj1, obj2) =>
  Object.keys(obj1).reduce(
    (a, key) => ({ ...a, [key]: obj2 !== null && obj2[key] !== undefined ? obj2[key] : null }),
    {}
  );

export default {
  name: 'PlanFinancialInfoForm',
  components: { Btn, DatePicker, NumberTextField },
  props: {
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    plan: { type: Object },
    readonlyFields: { type: Array },
    active: { type: Boolean, default: false },
  },
  apollo: {
    planTypes: {
      query: PLAN_TYPES_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load plan type list.' }
          : error;
      },
    },
  },
  data: () => ({
    ...validateRules,
    form: {
      typeOfPlan: null,
      ltfmrFundingAmount: null,
      capitalFundingAmount: null,
      capitalInfusionAmount: null,
      capitalInfusionYear: null,
      planStartYear: null,
      regionalCost: null,
      fundingEscalation: null,
      inflationCostEscalation: null,
      softCostPercentage: null,
    },
    years: generateRangeArray(new Date().getFullYear() - 20, new Date().getFullYear() + 20).reverse(),
    planTypeRules: [v => !!v || 'Plan Type is Required'],
    planTypes: [],
    planStartYearRules: [v => !!v || 'Plan Start Year is Required'],
    regionalCostRules: [
      v => (v !== null && v !== '') || 'Regional Cost is Required',
      validateRules.numberInRange(0, 200),
      validateRules.integerNumber,
    ],
    fundingEscalationRules: [
      v => (v !== null && v !== '') || 'Funding Escalation is Required',
      validateRules.numberInRange(0, 100),
      validateRules.floatNumber(1),
    ],
    inflationCostEscalationRules: [
      v => (v !== null && v !== '') || 'Inflation cost escalation',
      validateRules.numberInRange(0, 100),
      validateRules.floatNumber(1),
    ],
    softCostPercentageRules: [
      v => (v !== null && v !== '') || 'Soft Cost Percentage is Required',
      validateRules.numberInRange(0, 100),
      validateRules.floatNumber(1),
    ],
    internalLoading: false,
    error: null,
    changes: new Set(),
  }),
  watch: {
    plan: {
      immediate: true,
      handler(value) {
        if (value !== null) {
          this.error = null;
          this.form = copy(this.form, value.financialInfo);
          this.changes.clear();
          if (value.financialInfo === null) {
            this.changes = new Set(
              'regionalCost',
              'fundingEscalation',
              'inflationCostEscalation',
              'softCostPercentage'
            );
            this.form = {
              ...this.form,
              regionalCost: 100,
              fundingEscalation: 0,
              inflationCostEscalation: 0,
              softCostPercentage: 0,
            };
            if (this.active) {
              this.$emit('change');
            }
          }
        }
      },
    },
    'form.typeOfPlan'(val, oldVal) {
      if (oldVal !== null && val.id !== oldVal.id) {
        this.form.ltfmrFundingAmount = null;
      }
    },
    active: {
      immediate: true,
      handler(val) {
        if (val && this.changes.size > 0) {
          this.$emit('change');
        } else {
          this.$emit('reset');
        }
      },
    },
  },
  computed: {
    showLtfmFundingAmount() {
      return this.form.typeOfPlan && this.form.typeOfPlan.name === 'LTFM';
    },
    showCapitalFundingAmount() {
      return this.form.typeOfPlan && this.form.typeOfPlan.name.toLowerCase() === 'capital';
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onChange(key, value) {
      if (this.plan && this.plan.financialInfo) {
        if (!isEqual(this.plan.financialInfo[key], value)) {
          this.changes.add(key);
        } else if (this.changes.has(key)) {
          this.changes.delete(key);
        }
      } else {
        this.changes.add(key);
      }
      if (this.active) {
        this.$emit('change');
      }
    },
    onSubmit() {
      this.error = null;
      if (this.validate() && this.changes.size > 0) {
        const {
          typeOfPlan,
          ltfmrFundingAmount,
          capitalFundingAmount,
          capitalInfusionAmount,
          capitalInfusionYear,
          planStartYear,
          regionalCost,
          fundingEscalation,
          inflationCostEscalation,
          softCostPercentage,
        } = this.form;
        const variables = {
          typeOfPlanId: (typeOfPlan && typeOfPlan.id) || null,
          ltfmrFundingAmount,
          capitalFundingAmount,
          capitalInfusionAmount,
          capitalInfusionYear: capitalInfusionYear ? capitalInfusionYear : null,
          planStartYear: planStartYear,
          regionalCost,
          fundingEscalation,
          inflationCostEscalation,
          softCostPercentage,
        };
        if (this.plan.financialInfo === null || !this.plan.financialInfo.id) {
          this.addFinancialInfo({ ...variables, planId: this.plan.id });
        } else {
          this.updateFinancialInfo({ ...variables, id: this.plan.financialInfo.id });
        }
      } else if (this.validate() && this.changes.size === 0) {
        this.$emit('submit', this.plan);
      }
    },
    onBackClick() {
      this.$emit('back');
    },
    onReset() {
      this.form = copy(this.form, this.plan.financialInfo);
      this.error = null;
      this.$refs.form.resetValidation();
      this.changes.clear();
      this.$emit('reset');
    },
    addFinancialInfo(variables) {
      this.internalLoading = true;
      this.$apollo
        .mutate({
          mutation: ADD_FINANCIAL_INFO_MUTATION,
          variables: {
            input: variables,
          },
        })
        .then(({ data: { addFinancialInfo } }) => {
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Financial info was added successfully!`,
          });
          this.$emit('submit', { ...this.plan, financialInfo: addFinancialInfo });
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
    updateFinancialInfo(variables) {
      this.internalLoading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_FINANCIAL_INFO_MUTATION,
          variables: {
            input: variables,
          },
        })
        .then(({ data: { updateFinancialInfo } }) => {
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Financial info was updated successfully!`,
          });
          this.$emit('submit', { ...this.plan, financialInfo: updateFinancialInfo });
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
  },
};
