import ClientForm from '@/components/Clients/ClientForm/ClientForm.vue';
import ClientBreadcrumbs from '@/components/ClientBreadcrumbs/ClientBreadcrumbs.vue';
import CLIENT_QUERY from '@/graphql/queries/client.gql';

export default {
  name: 'EditClient',
  components: { ClientBreadcrumbs, ClientForm },
  props: { clientId: { type: Number | String } },
  apollo: {
    client: {
      query: CLIENT_QUERY,
      variables() {
        return { id: this.clientId };
      },
      error(e) {
        this.error = e;
      },
    },
  },
  data: () => ({
    client: null,
    error: null,
  }),
  computed: {
    loading() {
      return this.$apollo.queries.client.loading;
    },
  },
  methods: {
    onSubmit(client) {
      this.client = client;
    },
  },
};
