import Btn from '@/components/Btn/Btn.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import DELETE_CONTACT_MUTATION from '@/graphql/mutations/deleteContact.gql';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import eventHub from '@/utils/eventHub';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import Modal from '@/components/Modal/Modal.vue';
import EditContact from '@/components/Clients/ClientForm/Contacts/EditContact/EditContact.vue';

export default {
  name: 'ContactList',
  components: { Btn, DataTable, ConfirmationModal, Modal, EditContact },
  props: {
    client: { type: Object, default: null },
    contacts: { type: Array, default: [] },
    loading: { type: Boolean },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    DELETE_CONTACT_MUTATION,
    currentUser: null,
    headers: [
      {
        text: 'Name',
        value: 'name',
        type: 'text',
        dataType: 'string',
        width: '150px',
      },
      { text: 'Job Title', value: 'jobTitle', type: 'text', dataType: 'string', width: '100px' },
      { text: 'Phone', value: 'phone', type: 'text', dataType: 'string', width: '120px' },
      { text: 'Email', value: 'email', type: 'text', dataType: 'string', width: '150px' },
      { text: 'Facility/Location of Contact', value: 'location', type: 'text', dataType: 'string', width: '180px' },
      {
        text: 'Number of Years With Employer',
        value: 'countYearsTogether',
        type: 'number',
        dataType: 'number',
        width: '150px',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '53px',
        fixed: true,
        permissions: [PERMISSIONS.EDIT_CONTACT, PERMISSIONS.DELETE_CONTACT],
      },
    ],
  }),
  computed: {
    displayedItems() {
      return this.contacts.map(c => ({
        ...c,
        phone: c.phones && c.phones.length > 0 ? c.phones[0].phone : null,
      }));
    },
    displayedHeaders() {
      return this.headers.filter(
        h => !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser))
      );
    },
    showEditBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_CONTACT, this.currentUser);
    },
    showDeleteBtn() {
      return accessByPermissions(PERMISSIONS.DELETE_CONTACT, this.currentUser);
    },
  },
  methods: {
    onDelete(item) {
      return (store, { data: { deleteContact } }) => {
        if (deleteContact) {
          this.client.contacts = this.client.contacts.filter(contact => {
            return contact.id !== item.id;
          });
          eventHub.$emit('show-snackbar', { color: 'success', text: 'Contact was deleted successfully!' });
        }
      };
    },
  },
};
