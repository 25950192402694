import ClientForm from '@/components/Clients/ClientForm/ClientForm.vue';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import PERMISSIONS from '@/enums/permissions';
export default {
  name: 'AddClient',
  components: { ClientForm, Breadcrumbs },
  data: () => ({
    client: {
      name: null,
      district: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      county: null,
      description: null,
      icsOffice: null,
      clientType: null,
      logoUrl: null,
      contacts: null,
      facilities: null,
    },
    breadcrumbs: [
      {
        text: 'Clients',
        to: { name: 'clients' },
        permission: PERMISSIONS.VIEW_CLIENTS,
      },
      {
        text: 'Add Client',
        disabled: true,
      },
    ],
  }),
  methods: {
    onSubmit(client) {
      this.client = client;
    },
  },
};
