import Btn from '@/components/Btn/Btn.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import CLIENTS_QUERY from '@/graphql/queries/clients.gql';
import DELETE_CLIENT_MUTATION from '@/graphql/mutations/deleteClient.gql';
import eventHub from '@/utils/eventHub';
export default {
  name: 'ClientList',
  components: { Btn, DataTable, ConfirmationModal },
  props: {
    clients: { type: Array, default: [] },
    loading: { type: Boolean },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    DELETE_CLIENT_MUTATION,
    headers: [
      { text: 'Client Name', value: 'name', type: 'text', dataType: 'string', width: '100px' },
      { text: 'Creator', value: 'creatorUsername', type: 'text', dataType: 'string', width: '100px' },
      { text: 'ICS Responsible Office', value: 'icsOfficeName', type: 'select', dataType: 'string', width: '100px' },
      { text: 'Client Type', value: 'clientTypeName', type: 'select', dataType: 'string', width: '100px' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '40px',
        fixed: true,
        permissions: [PERMISSIONS.EDIT_CLIENT, PERMISSIONS.DELETE_CLIENT],
      },
    ],
  }),
  computed: {
    displayedItems() {
      return this.clients
        ? this.clients.map(client => ({
            ...client,
            icsOfficeName: (client.icsOffice && client.icsOffice.name) || '',
            clientTypeName: (client.clientType && client.clientType.name) || '',
            creatorUsername: (client.creator && client.creator.username) || '',
            canViewDetails: accessByPermissions(PERMISSIONS.VIEW_CLIENT, this.currentUser),
            canDelete: !client.plans || client.plans.length === 0,
          }))
        : [];
    },
    displayedHeaders() {
      return this.headers.filter(
        h => !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser))
      );
    },
    showEditBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_CLIENT, this.currentUser);
    },
    showDeleteBtn() {
      return accessByPermissions(PERMISSIONS.DELETE_CLIENT, this.currentUser);
    },
  },
  methods: {
    onDelete(item) {
      return (store, { data: { deleteClient } }) => {
        if (deleteClient) {
          // Read the data from our cache for this query.
          const data = store.readQuery({
            query: CLIENTS_QUERY,
          });
          data.clients = data.clients.filter(client => {
            return client.id !== item.id;
          });
          store.writeQuery({
            query: CLIENTS_QUERY,
            data,
          });
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: 'Client was deleted from the system successfully!',
          });
        }
      };
    },
  },
};
