<template>
  <div class="plan-details">
    <plan :planId="$route.params.planId"></plan>
  </div>
</template>
<script>
import Plan from '@/components/Plan/Plan.vue';
export default {
  name: 'PlanDetails',
  components: { Plan },
};
</script>
