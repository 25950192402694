import DefinitionList from '@/components/DefinitionList/DefinitionList.vue';

export default {
  name: 'PlanFinancialInfo',
  components: { DefinitionList },
  props: {
    plan: { type: Object, default: null },
  },
  data: () => ({
    financialInfoLabels: [
      {
        label: 'Plan Type',
        value: 'typeOfPlan.name',
      },
      {
        label: 'LTFMR Funding Amount (annual)',
        value: 'ltfmrFundingAmount',
        type: 'currency',
      },
      {
        label: 'Capital Funding Amount',
        value: 'capitalFundingAmount',
        type: 'currency',
      },
      {
        label: 'Capital Infusion Amount',
        value: 'capitalInfusionAmount',
        type: 'currency',
      },
      {
        label: 'Capital Infusion Year',
        value: 'capitalInfusionYear',
      },
      {
        label: 'Plan Start Year',
        value: 'planStartYear',
      },
      {
        label: 'Regional Cost',
        value: 'regionalCost',
        type: 'percentage',
      },
      {
        label: 'Funding Escalation',
        value: 'fundingEscalation',
        type: 'percentage',
      },
      {
        label: 'Inflation Cost Escalation',
        value: 'inflationCostEscalation',
        type: 'percentage',
      },
      {
        label: 'Soft Cost Percentage',
        value: 'softCostPercentage',
        type: 'percentage',
      },
    ],
  }),
  computed: {
    displayedFinancialInfoLabels() {
      return this.plan && this.plan.financialInfo
        ? this.financialInfoLabels
            .filter(label => {
              return label.value === 'ltfmrFundingAmount'
                ? this.plan.financialInfo.typeOfPlan.name.toLowerCase() !== 'capital'
                : label;
            })
            .filter(label => {
              return label.value === 'capitalFundingAmount'
                ? this.plan.financialInfo.typeOfPlan.name.toLowerCase() !== 'ltfm'
                : label;
            })
        : [];
    },
  },
};
