import Btn from '@/components/Btn/Btn.vue';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import validateRules from '@/services/validateRules';
import { generateRangeArray } from '@/utils/generator';

export default {
  name: 'CompleteComponentForm',
  components: { Btn, NumberTextField },
  props: {
    loading: { type: Boolean, default: false },
    component: { type: Object },
    plan: { type: Object },
  },
  data: vm => {
    const startYear = vm.plan && vm.plan.financialInfo && parseInt(vm.plan.financialInfo.planStartYear);
    const years = startYear ? generateRangeArray(startYear, startYear + 19) : [];
    return {
      ...validateRules,
      yearCompletedRules: [v => (v !== null && v !== '') || 'Year completed is Required'],
      years: years,
      actualCostRules: [validateRules.positiveNumber, validateRules.floatNumber(2)],
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit() {
      if (this.validate()) {
        this.$emit('submit', this.component);
      }
    },
  },
};
