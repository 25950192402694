import Btn from '@/components/Btn/Btn.vue';
import CLONE_PLAN_MUTATION from '@/graphql/mutations/clonePlan.gql';
import PLANS_QUERY from '@/graphql/queries/plans.gql';
import eventHub from '@/utils/eventHub';

export default {
  name: 'ClonePlanModal',
  components: { Btn },
  props: {
    plan: {
      type: Object,
    },
  },
  data: () => ({
    CLONE_PLAN_MUTATION,
    name: null,
    nameRules: [v => !!v || 'Name is Required'],
  }),
  computed: {
    isNameValid() {
      return this.name === null || this.name === '';
    },
  },
  methods: {
    onClonePlan(store, { data: { clonePlan } }) {
      if (clonePlan) {
        // Read the data from our cache for this query.
        const data = store.readQuery({
          query: PLANS_QUERY,
        });
        data.plans.unshift(clonePlan);
        store.writeQuery({
          query: PLANS_QUERY,
          data,
        });
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: 'Plan was cloned successfully!',
        });
      }
    },
  },
};
