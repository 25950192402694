import Vue from 'vue';
import { VStepper } from 'vuetify/lib';
// Create Base Mixins and Define Custom Properties
const base = Vue.extend({ mixins: [VStepper] });
export default base.extend({
  name: 'Stepper',
  components: {
    VStepper,
  },
  props: {
    nonLinear: {
      type: Boolean,
      default: true,
    },
  },
});
