import PrimaryNav from '@/components/PrimaryNav/PrimaryNav.vue';
export default {
  name: 'AppNavigationDrawer',
  components: { PrimaryNav },
  data() {
    return {
      drawer: true,
      mini: true,
    };
  },
};
