import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
export default {
  name: 'ConfirmationModal',
  components: {
    Modal,
    Btn,
  },
  props: {
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    mutation: { type: Object, default: '' },
    variables: { type: Object, default: null },
    update: { types: Function },
    done: { type: Function },
    okBtn: {
      type: Object,
      default: () => ({
        label: 'Ok',
      }),
    },
    cancelBtn: {
      type: Object,
      default: () => ({
        label: 'Cancel',
      }),
    },
  },
  methods: {
    onDone(close) {
      close();
      if (this.done) this.done();
    },
  },
};
