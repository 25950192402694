import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import CompletedComponents from './CompletedComponents/CompletedComponents.vue';
import ActiveComponents from './ActiveComponents/ActiveComponents.vue';
import BudgetPlanning from './BudgetPlanning/BudgetPlanning.vue';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';

export default {
  name: 'Components',
  components: { Btn, Modal, ActiveComponents, CompletedComponents, BudgetPlanning },
  props: {
    plan: {
      type: Object,
      default: null,
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    currentUser: null,
    tab: null,
  }),
  computed: {
    showAddComponentBtn() {
      return accessByPermissions(PERMISSIONS.ADD_PLAN_COMPONENT, this.currentUser);
    },
  },
  methods: {
    onBackClick() {
      this.$emit('back');
    },
  },
};
