import Stepper from '@/components/Stepper/Stepper.vue';
import StepperStep from '@/components/StepperStep/StepperStep.vue';
import PlanGeneralInfoForm from './PlanGeneralInfoForm/PlanGeneralInfoForm.vue';
import PlanFinancialInfoForm from './PlanFinancialInfoForm/PlanFinancialInfoForm.vue';
import Components from './Components/Components.vue';

export default {
  name: 'PlanForm',
  components: { Stepper, StepperStep, PlanGeneralInfoForm, PlanFinancialInfoForm, Components },
  props: {
    plan: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonlyFields: { type: Array },
  },
  data: () => ({
    step: 1,
    lock: false,
    lastCompleted: 0,
  }),
  watch: {
    step(value) {
      if (value > this.lastCompleted) {
        this.lastCompleted = value - 1;
      }
    },
    plan(value) {
      if (value && value.id) {
        if (value.financialInfo !== null) {
          this.lastCompleted = 2;
        } else {
          this.lastCompleted = 1;
        }
      }
    },
  },
  methods: {
    onUpdatePlanInfo(plan) {
      this.step = 2;
      this.lock = false;
      this.$emit('submit', plan);
    },
    onChangePlanInfo() {
      this.lock = true;
    },
    onResetChangesPlanInfo() {
      this.lock = false;
    },
    onUpdateFinancialInfo(plan) {
      this.step = 3;
      this.lock = false;
      this.$emit('submit', plan);
    },
    onChangeFinancialInfo() {
      this.lock = true;
    },
    onResetChangesFinancialInfo() {
      this.lock = false;
    },
  },
};
