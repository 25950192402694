var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('alert',{attrs:{"error":_vm.error}}),_c('data-table',{attrs:{"items":_vm.displayedItems,"headers":_vm.displayedHeaders,"height":"611px","item-key":"id","loading":_vm.loading,"show-select":_vm.showSelect,"select-label":"Include"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.canViewDetails)?_c('router-link',{staticClass:"date-cell",attrs:{"to":{ name: 'plan-details', params: { planId: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}},{key:"item.dateOfCreation",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"date-cell"},[_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("utcDate")(item.dateOfCreation)))])])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"date-cell"},[_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("date")(item.updatedAt)))]),_vm._v(" "),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("time")(item.updatedAt)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('actions-menu',{attrs:{"actions":item.actions}})]}}]),model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }