import CLIENT_QUERY from '@/graphql/queries/client.gql';
import ClientBreadcrumbs from '@/components/ClientBreadcrumbs/ClientBreadcrumbs.vue';
import ClientInfo from '@/components/ClientInfo/ClientInfo.vue';
import Btn from '@/components/Btn/Btn.vue';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';

export default {
  name: 'Client',
  components: { ClientInfo, ClientBreadcrumbs, Btn },
  props: { clientId: { type: Number | String } },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    client: {
      query: CLIENT_QUERY,
      variables() {
        return { id: this.clientId };
      },
      error(e) {
        this.error = e;
      },
    },
  },
  data: () => ({
    client: null,
    currentUser: null,
    error: null,
  }),
  computed: {
    loading() {
      return this.$apollo.queries.client.loading;
    },
    showCreatePlan() {
      return (
        accessByPermissions(PERMISSIONS.CREATE_PLAN, this.currentUser) &&
        this.client &&
        this.client.facilities &&
        this.client.facilities.length > 0
      );
    },
    showEditClient() {
      return accessByPermissions(PERMISSIONS.EDIT_CLIENT, this.currentUser);
    },
  },
};
