import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import ComponentList from './ComponentList/ComponentList.vue';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import COMPONETS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';
import { COMPONENT_STATUS } from '@/enums/componentStatus';

export default {
  name: 'CompletedComponents',
  components: { Btn, Modal, ComponentList },
  props: {
    plan: {
      type: Object,
      default: null,
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    componentsOfNeed: {
      query: COMPONETS_OF_NEEDS,
      variables() {
        return {
          planId: (this.plan && this.plan.id) || null,
          status: COMPONENT_STATUS.COMPLETED,
        };
      },
      skip() {
        return this.plan === null || !this.plan.id;
      },
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load completed components.' }
          : error;
      },
    },
  },
  data: () => ({
    currentUser: null,
    componentsOfNeed: null,
    error: null,
  }),
  computed: {
    loading() {
      return this.$apollo.queries.componentsOfNeed.loading;
    },
  },
};
