import PLAN_QUERY from '@/graphql/queries/plan.gql';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import PlanInfo from '@/components/PlanInfo/PlanInfo.vue';
import Btn from '@/components/Btn/Btn.vue';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import COMPONETS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import { COMPONENT_STATUS } from '@/enums/componentStatus';

export default {
  name: 'Plan',
  components: { PlanInfo, Breadcrumbs, Btn },
  props: { planId: { type: Number | String } },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    plan: {
      query: PLAN_QUERY,
      variables() {
        return { id: this.planId };
      },
      error(e) {
        this.error = e;
      },
    },
    componentsOfNeed: {
      query: COMPONETS_OF_NEEDS,
      variables() {
        return {
          planId: this.planId || null,
          status: COMPONENT_STATUS.OPEN,
        };
      },
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load plan components.' }
          : error;
      },
    },
  },
  data: () => ({
    plan: null,
    componentsOfNeed: null,
    currentUser: null,
    error: null,
  }),
  computed: {
    loading() {
      return this.$apollo.queries.componentsOfNeed.loading;
    },
    showEditPlan() {
      return accessByPermissions(PERMISSIONS.EDIT_PLAN, this.currentUser);
    },
    breadcrumbs() {
      return [
        {
          text: 'Clients',
          to: { name: 'clients' },
          permission: PERMISSIONS.VIEW_CLIENTS,
        },
        ...(this.plan && this.plan.client
          ? [
              {
                text: this.plan.client.name || '',
                href: '/client-details/' + this.plan.client.id,
                permission: PERMISSIONS.VIEW_CLIENTS,
              },
            ]
          : []),
        {
          text: (this.plan && this.plan.name) || '',
          disabled: true,
        },
      ];
    },
  },
};
