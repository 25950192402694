import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import Clients from '@/components/Clients/Clients.vue';
import Btn from '@/components/Btn/Btn.vue';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
export default {
  components: { Clients, Btn },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-first',
    },
  },
  data: () => ({
    currentUser: null,
  }),
  computed: {
    showAddClient() {
      return accessByPermissions(PERMISSIONS.ADD_CLIENT, this.currentUser);
    },
  },
};
