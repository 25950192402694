import Btn from '@/components/Btn/Btn.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import DELETE_FACILITY_MUTATION from '@/graphql/mutations/deleteFacility.gql';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import eventHub from '@/utils/eventHub';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import Modal from '@/components/Modal/Modal.vue';
import TruncatedText from '@/components/TruncatedText/TruncatedText.vue';
import EditFacility from '@/components/Clients/ClientForm/Facilities/EditFacility/EditFacility.vue';

export default {
  name: 'FacilityList',
  components: { Btn, DataTable, ConfirmationModal, Modal, EditFacility, TruncatedText },
  props: {
    client: { type: Object, default: null },
    facilities: { type: Array, default: [] },
    loading: { type: Boolean },
    readonly: { type: Boolean, default: true },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: vm => ({
    DELETE_FACILITY_MUTATION,
    currentUser: null,
    headers: [
      {
        text: 'Name',
        value: 'name',
        type: 'text',
        dataType: 'string',
        width: '180px',
        fixed: true,
      },
      { text: 'Square Footage', value: 'squareFootage', type: 'number', dataType: 'number', width: '80px' },
      { text: 'Age(s) of Facility', value: 'age', type: 'text', dataType: 'string', width: '120px' },
      { text: 'Occupancy', value: 'occupancy', type: 'number', dataType: 'number', width: '80px' },
      { text: 'Capacity', value: 'capacity', type: 'number', dataType: 'number', width: '80px' },
      { text: 'Use', value: 'use', type: 'text', dataType: 'string', width: '100px' },
      { text: 'Site Acres', value: 'siteSize', type: 'number', dataType: 'number', width: '80px' },
      { text: 'Replacement Cost', value: 'replacementCost', type: 'number', dataType: 'currency', width: '100px' },
      { text: 'Link to Facility Photos', value: 'urlPhotos', width: '130px' },
      { text: 'Summary of Facility', value: 'summary', type: 'text', dataType: 'longstring', width: '200px' },
      ...(!vm.readonly
        ? [
            {
              text: 'Actions',
              value: 'actions',
              sortable: false,
              width: '53px',
              fixed: true,
              permissions: [PERMISSIONS.EDIT_FACILITY, PERMISSIONS.DELETE_FACILITY],
            },
          ]
        : []),
    ],
  }),
  computed: {
    displayedItems() {
      return this.facilities;
    },
    displayedHeaders() {
      return this.headers.filter(
        h => !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser))
      );
    },
    showEditBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_FACILITY, this.currentUser);
    },
    showDeleteBtn() {
      return accessByPermissions(PERMISSIONS.DELETE_FACILITY, this.currentUser);
    },
  },
  methods: {
    onDelete(item) {
      return (store, { data: { deleteFacility } }) => {
        if (deleteFacility) {
          this.client.facilities = this.client.facilities.filter(facility => {
            return facility.id !== item.id;
          });
          eventHub.$emit('show-snackbar', { color: 'success', text: 'Facility was deleted successfully!' });
        }
      };
    },
  },
};
