import PERMISSIONS from '@/enums/permissions';

const NAV_ITEMS = [
  {
    title: 'Clients',
    icon: 'far fa-address-card',
    to: { name: 'clients' },
    permission: PERMISSIONS.VIEW_CLIENTS,
  },
  {
    title: 'Client Information',
    icon: 'far fa-address-card',
    to: { name: 'client-details' },
    params: ['clientId'],
    permission: PERMISSIONS.VIEW_CLIENT,
    roles: ['user', 'viewer'],
  },
  { title: 'Plans', icon: 'fa-list-ul', to: { name: 'plans' }, permission: PERMISSIONS.VIEW_PLANS },
  { title: 'Manage Users', icon: 'fa-user-cog', to: { name: 'manage-users' }, permission: PERMISSIONS.VIEW_USERS },
  {
    title: 'Manage Database',
    icon: 'fa-database',
    to: { name: 'manage-database' },
    permission: PERMISSIONS.VIEW_DATABASE,
  },
  {
    title: 'Settings',
    icon: 'fa-cog',
    to: { name: 'settings' },
    permission: PERMISSIONS.CHANGE_ROLE_PERMISSIONS,
  },
];

export default NAV_ITEMS;
