<template>
  <div class="add-client">
    <client></client>
  </div>
</template>

<script>
import Client from '@/components/Clients/AddClient/AddClient.vue';
export default {
  name: 'AddClient',
  components: { Client },
};
</script>
