import DataTable from '@/components/DataTable/DataTable.vue';
import { generateRangeArray } from '@/utils/generator';
import { calculateBudget, getClientBudget } from '@/services/componentBudget';

export default {
  name: 'BudgetTable',
  components: { DataTable },
  props: {
    plan: { type: Object, default: null },
    components: { type: Array, default: [] },
    loading: { type: Boolean },
    changes: { type: Array },
  },
  data: vm => {
    const startYear = vm.plan && vm.plan.financialInfo && parseInt(vm.plan.financialInfo.planStartYear);
    const years = startYear ? generateRangeArray(startYear, startYear + 19) : [];
    const yearCols = years.map(y => ({
      text: y,
      value: y,
      sortable: false,
      width: '80px',
    }));
    return {
      years: years,
      displayedHeaders: [
        {
          text: '',
          value: 'label',
          sortable: false,
          fixed: true,
          width: '150px',
        },
        ...yearCols,
      ],
      items: [],
    };
  },
  computed: {
    displayedItems() {
      if (!this.plan || !this.plan.financialInfo) return [];
      const {
        typeOfPlan,
        capitalFundingAmount,
        ltfmrFundingAmount,
        capitalInfusionYear,
        capitalInfusionAmount,
        fundingEscalation,
        planStartYear,
      } = this.plan.financialInfo;
      const clientBudgetPerYear = (typeOfPlan.name === 'Capital' ? capitalFundingAmount : ltfmrFundingAmount) || null;
      const clientBudget = this.years.reduce((b, y) => {
        const budgetIncludeEscalation = fundingEscalation
          ? getClientBudget(clientBudgetPerYear, fundingEscalation, y, planStartYear)
          : clientBudgetPerYear;
        const budget =
          capitalInfusionYear && capitalInfusionAmount && capitalInfusionYear === y
            ? budgetIncludeEscalation + capitalInfusionAmount
            : budgetIncludeEscalation;
        return {
          ...b,
          [y]: {
            value: budget,
          },
        };
      }, {});
      const componentsBudget = this.years.reduce((b, y) => {
        const budget = this.components
          .filter(c => {
            const changed = this.changes.find(ch => ch.id === c.id);
            return changed ? changed.yearForImprovement === y : c.yearForImprovement === y;
          })
          .reduce((b, c) => {
            const changed = this.changes.find(ch => ch.id === c.id);
            const { budgetAmount } = calculateBudget(changed ? changed : c, this.plan);
            return b + budgetAmount;
          }, 0);
        const clientB = clientBudget[y].value;
        return {
          ...b,
          [y]: { value: budget.toString(), className: clientB && clientB - budget < 0 ? 'out-of-budget' : '' },
        };
      }, {});
      return [
        { ...clientBudget, label: 'Client Budget', key: 1 },
        { ...componentsBudget, label: 'Components Budget', key: 2 },
      ];
    },
  },
};
